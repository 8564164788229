/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable camelcase */
import React from 'react';
import classNames from 'classnames';
import Typography from '@common_typography';
import { formatPrice } from '@helper_currency';
import useStyles from '../style';

const OtherProductTypePrice = (props) => {
    const {
        priceRange, specialFromDate, specialToDate, additionalPrice, pageType, maxDiscount = {},
    } = props;

    const styles = useStyles();
    const otherPrice = additionalPrice || 0;

    const regularMinPrice = priceRange.minimum_price.regular_price;
    const regularMaxPrice = priceRange.maximum_price.regular_price;
    const finalMinPrice = priceRange.minimum_price.final_price;
    const finalMaxPrice = priceRange.maximum_price.final_price;
    const maxDiscountPercent = (maxDiscount || {}).percent_off || (priceRange || {}).maximum_price.discount.percent_off || 0;

    let validSpecial = true;
    const nowTime = new Date(Date.now()).getTime();
    if (specialFromDate && specialFromDate !== null) {
        const startTime = new Date(specialFromDate).getTime();
        if (nowTime < startTime) validSpecial = false;
    }
    if (specialToDate && specialToDate !== null) {
        const endTime = new Date(specialToDate).getTime();
        if (nowTime > endTime) validSpecial = false;
    }

    const getPriceRange = () => {
        if (finalMinPrice.value !== finalMaxPrice.value) {
            return `${formatPrice(finalMinPrice.value, finalMinPrice.currency)} - ${formatPrice(finalMaxPrice.value, finalMaxPrice.currency)}`;
        }
        return formatPrice(finalMinPrice.value, finalMinPrice.currency);
    };

    if (pageType === 'wishlist') {
        return (
            <>
                {
                    regularMinPrice.value === finalMinPrice.value ? (
                        <Typography
                            variant="span"
                            type="bold"
                            letter="capitalize"
                            className={classNames(styles.textPriceDiscount, 'price_text')}
                        >
                            {
                                formatPrice(finalMinPrice.value, finalMinPrice.currency)
                            }
                        </Typography>
                    ) : (
                        <>

                            <div className={styles.discountWishlist}>
                                {
                                    validSpecial ? (
                                        <Typography
                                            variant="span"
                                            letter="capitalize"
                                            className={classNames(styles.oldPrice, 'price_old')}
                                        >
                                            <strike>{formatPrice(regularMinPrice.value + otherPrice, regularMinPrice.currency)}</strike>
                                        </Typography>
                                    ) : null
                                }
                            </div>
                            <Typography
                                variant="span"
                                type="bold"
                                letter="capitalize"
                                className={classNames(styles.textPriceDiscount, 'price_text')}
                            >
                                {
                                    validSpecial ? formatPrice(finalMinPrice.value, finalMinPrice.currency)
                                        : formatPrice(regularMinPrice.value + otherPrice, regularMinPrice.currency)
                                }
                            </Typography>
                        </>
                    )
                }
            </>
        );
    }

    return (
        <div className={styles.container}>
            {
                pageType === 'product-list' ? (
                    <div className="configurable-discount-container">
                        {
                            maxDiscountPercent > 0 && (
                                <div className="line-through-price">
                                    <span className="text-discount">
                                        {`${Math.round(maxDiscountPercent)}% OFF`}
                                    </span>
                                    <span className={classNames('old-price', 'configurable-old-price')}>
                                        {formatPrice(regularMaxPrice.value, regularMaxPrice.currency)}
                                    </span>
                                </div>
                            )
                        }
                        <div className="text-price-discount">
                            {
                                maxDiscountPercent > 0 ? getPriceRange()
                                    : formatPrice(regularMinPrice.value, regularMinPrice.currency)
                            }
                        </div>
                    </div>
                ) : (
                    <>
                        <div>
                            <Typography
                                variant="span"
                                type="bold"
                                letter="capitalize"
                                className={classNames(styles.textPriceDiscount, 'price_text', 'clear-margin-padding')}
                            >
                                {
                                    maxDiscountPercent > 0 ? getPriceRange()
                                        : formatPrice(regularMinPrice.value, regularMinPrice.currency)
                                }
                            </Typography>
                        </div>
                        {
                            maxDiscountPercent > 0 && (
                                <div className={styles.discountContainer}>
                                    <div className={styles.priceDiscount}>
                                        <Typography
                                            variant="span"
                                            type="bold"
                                            letter="capitalize"
                                            className={classNames(styles.textDiscount, 'price_discount')}
                                        >
                                            {`${Math.round(maxDiscountPercent)}%`}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="span"
                                            letter="capitalize"
                                            className={classNames(styles.oldPrice, 'price_old')}
                                        >
                                            <strike>{formatPrice(regularMaxPrice.value, regularMaxPrice.currency)}</strike>
                                        </Typography>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

export default OtherProductTypePrice;
